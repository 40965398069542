import { PortableText } from '@portabletext/react';
import React from 'react';

const myPortableTextComponents = {
    block: {
        normal: ({ children }) => <p>{children}</p>,
        h2: ({ children }) => <h2>{children}</h2>,
        h1: ({ children }) => <h1>{children}</h1>,
        h4: ({ children }) => <h1 className={"slightlylarger"}>{children}</h1>,
        h5: ({ children }) => <h5>{children}</h5>,
    },
    marks: {
        strong: ({children}) => <strong>{children}</strong>,
        red: ({children}) => <span className={"color-red"}>{children}</span>,
        link: ({value, children}) => {
            const target = (value?.href || '').startsWith('http') ? '_blank' : undefined
            return (
                <a href={value?.href} target={target} rel={target === '_blank' && 'noindex nofollow'} className="hover-underline">
                    {children}
                </a>
            )
        },
    },
};

function MyPortableText({ value }) {
    return <PortableText value={value} components={myPortableTextComponents} />;
}

export default MyPortableText;
