import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import {Link} from "gatsby";
import {localeLink} from "../util";

const Menu = styled.div`
    display: ${({nav}) => (nav ? "flex" : "none")} !important;
`

const Navigation = ({location, lang, animated, metadata, placeholder, servicesheadline, aboutheadline, photoheadline, videoheadline}) => {

    const [nav, changeNavState] = useState(false);
    const [navBtn, changeNavBtnState] = useState(false);

    const breakpoint = useBreakpoint();

    const [navbar, setNavbar] = useState(false)

    const changeNavBar = () => {
        if (!navBtn) {
            if (window.scrollY >= 50) {
                setNavbar(true)
            } else {
                setNavbar(false)
            }
        } else{
            setNavbar(true)
        }
    }

    useEffect(() => {
        changeNavBar();

        window.addEventListener("scroll", changeNavBar);
    });

    const menuRef = useRef();

    const changeNav = () => {
        if(nav){
            document.body.classList.remove("stop-scrolling");
            menuRef.current.classList.add('fade-out');
            changeNavBtnState(false);
            setTimeout(() => {
                changeNavState(false);
                if(menuRef.current){
                    menuRef.current.classList.remove('fade-out');
                }
            }, 300)
        }
        else{
            document.body.classList.add("stop-scrolling");
            changeNavState(true);
            changeNavBtnState(true);
        }
    }

    console.log(location);

    return (
        <nav className={animated ? navbar ? "navigation nav-animated active" : "navigation nav-animated" : navbar ? "navigation active" : "navigation"}>
            <div className="left">
                <Link to={localeLink("/", location)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                         viewBox="0 0 169.5 101">
                        <g id="Ebene_1">
                            <g>
                                <g>
                                    <path className="svg-fill-nav" d="M21.2,21.1h9.5v60.3h-9.5V21.1z"/>
                                    <path className="svg-fill-nav" d="M42.1,72.4c1,0.1,3.1,0.4,4.7,0.4c3,0,5.4-1.1,5.4-5.8V21h9.5v45.3c0,12.1-6.5,15.1-13.6,15.1
				c-2.1,0-4.5-0.3-6-0.5V72.4z"/>
                                    <path className="svg-fill-nav" d="M76,35.5c0-9.6,5.1-15.1,14.4-15.1c9.3,0,14.4,5.5,14.4,15.1v31.3c0,9.6-5.1,15.1-14.4,15.1
				c-9.3,0-14.4-5.5-14.4-15.1V35.5z M85.5,67.5c0,4.3,1.9,5.9,4.9,5.9s4.9-1.6,4.9-5.9V34.9c0-4.3-1.9-5.9-4.9-5.9
				s-4.9,1.6-4.9,5.9V67.5z"/>
                                    <path className="svg-fill-nav" d="M147.8,34.9v2.2c0,6.2-1.9,10.1-6.1,12.1v0.2c5.1,2,7.1,6.5,7.1,12.8V67c0,9.3-4.9,14.3-14.4,14.3h-14.9
				V21.1h14.3C143.6,21.1,147.8,25.6,147.8,34.9z M128.9,29.7v15.9h3.7c3.5,0,5.7-1.5,5.7-6.4v-3.4c0-4.3-1.5-6.2-4.8-6.2h-4.6V29.7
				z M128.9,54.2v18.5h5.4c3.2,0,4.9-1.5,4.9-5.9v-5.3c0-5.6-1.8-7.3-6.1-7.3C133.1,54.2,128.9,54.2,128.9,54.2z"/>
                                </g>
                                <path className="svg-fill-nav" d="M42.1,1.5V11H160v80.5H9.5V1.7H0V101h169.5V1.5H42.1z"/>
                                <g>
                                    <circle className="svg-fill-red svg-fill-red-animated" cx="25.9" cy="6.2" r="6.2"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </Link>
            </div>
            {placeholder ? <></> :
            <div className="right">
                <div className="desktop vcenter">
                    <AnchorLink to={localeLink("/#services", location)} className={"nav-el hover-underline"}>{location?.href?.includes(location?.origin + '/en/') || location?.href === location?.origin + '/en' ? servicesheadline.en : servicesheadline.de}</AnchorLink>
                    <AnchorLink to={localeLink("/#videography", location)} className={"nav-el hover-underline"}>{location?.href?.includes(location?.origin + '/en/') || location?.href === location?.origin + '/en' ? videoheadline.en : videoheadline.de}</AnchorLink>
                    <AnchorLink to={localeLink("/#photography", location)} className={"nav-el hover-underline"}>{location?.href?.includes(location?.origin + '/en/') || location?.href === location?.origin + '/en' ? photoheadline.en : photoheadline.de}</AnchorLink>
                    <AnchorLink to={localeLink("/#about", location)} className={"nav-el hover-underline"}>{location?.href?.includes(location?.origin + '/en/') || location?.href === location?.origin + '/en' ? aboutheadline.en : aboutheadline.de}</AnchorLink>
                    <AnchorLink className={"btn btn-red hover-moveup"} to={localeLink("/contact", location)}>{location?.href?.includes(location?.origin + '/en/') || location?.href === location?.origin + '/en' ? metadata.contact.en : metadata.contact.de}</AnchorLink>
                    {location?.href?.includes(location?.origin + '/en/') || location?.href === location?.origin + '/en' ?
                        <Link to={location.pathname === "/en/" ? "/" : location.pathname.replace('/en', '')} className={"nav-el nav-lang hover-underline"}>{lang.en}</Link> : <Link to={location.pathname === "/" ? "/en/" : "/en" + location.pathname} className={"nav-el nav-lang hover-underline"}>{lang.de}</Link>}
                   </div>
                <div className={navBtn ? "open burger mobile" : "burger mobile"} onClick={() => {
                    changeNav();changeNavBar();
                }}>
                    <div className="line first"></div>
                    <div className="line second"></div>
                    <div className="line third"></div>
                </div>
            </div>}
            {placeholder ? <></> :
            <Menu className="menu fade-in" nav={nav} ref={menuRef}>
                <div className="navigation__links">
                    <AnchorLink to={localeLink("/#services", location)} onAnchorLinkClick={() => {changeNav();}} className={"nav-el hover-underline"}>{servicesheadline.de}</AnchorLink>
                    <AnchorLink to={localeLink("/#videography", location)}  onAnchorLinkClick={() => {changeNav();}} className={"nav-el hover-underline"}>{videoheadline.de}</AnchorLink>
                    <AnchorLink to={localeLink("/#photography", location)}  onAnchorLinkClick={() => {changeNav();}} className={"nav-el hover-underline"}>{photoheadline.de}</AnchorLink>
                    <AnchorLink to={localeLink("/#about", location)}  onAnchorLinkClick={() => {changeNav();}} className={"nav-el hover-underline"}>{aboutheadline.de}</AnchorLink>
                    <AnchorLink to={localeLink("/contact", location)}  onAnchorLinkClick={() => {changeNav();}} className={"nav-el hover-underline"}>{metadata.contact.de}</AnchorLink>
                    {location?.href?.includes(location?.origin + '/en/') || location?.href === location?.origin + '/en' ?
                        <Link to={location.pathname === "/en/" ? "/" : location.pathname.replace('/en', '')} onClick={() => {changeNav();}} className={"nav-el nav-lang hover-underline"}>{lang.en}</Link> : <Link to={location.pathname === "/" ? "/en/" : "/en" + location.pathname} onClick={() => {changeNav();}} className={"nav-el nav-lang hover-underline"}>{lang.de}</Link>}
                </div>
            </Menu>
            }
        </nav>
    )
}

export default Navigation;

/*

<Link to={localeLink("/#angebot", location)} onClick={() => {
                        changeNavState(!nav)
                    }} className={"uppercase scene bold color-dark hover-moveup"}>{metadata.angebot}</Link>
                    <Link to={localeLink("/#kunden-referenzen", location)} onClick={() => {
                        changeNavState(!nav)
                    }} className={"uppercase scene bold color-dark hover-moveup"}>{metadata.kunden}</Link>
                    <Link to={localeLink("/#vita", location)} onClick={() => {
                        changeNavState(!nav)
                    }} className={"uppercase scene bold color-dark hover-moveup"}>Vita</Link>
                    {settings.jobsexternal ?
                        <a href={settings.jobsexternallink} target={"_blank"} className={"uppercase scene bold color-dark hover-moveup"}>Jobs</a>
                        :
                        <Link to={localeLink("/jobs", location)} className={"uppercase scene bold color-dark hover-moveup"}>Jobs</Link>
                    }


<div className="navigation__corner left top"></div>
            <div className="navigation__corner left bottom"></div>
            <div className="navigation__corner top right"></div>
            <div className="navigation__corner bottom right"></div>

            localeLink("/", location)

             <AnchorLink to={localeLink("/#about", location)} className={"nav-el hover-underline"}>About
                        me</AnchorLink>
                    <AnchorLink to={localeLink("/#work", location)} className={"nav-el hover-underline"}>Work</AnchorLink>
                    <AnchorLink className={"btn btn-red hover-moveup"} to={localeLink("/contact", location)}>Contact</AnchorLink>

                     {lang === 'EN' ?
                        <Link to={location.pathname === "/" ? "/en/" : "/en" + location.pathname} className={"nav-el nav-lang hover-underline"}>{lang}</Link> :
                        <Link to={location.pathname === "/en/" ? "/" : location.pathname.replace('/en', '')} className={"nav-el nav-lang hover-underline"}>{lang}</Link>}

        transform: ${({nav}) => (nav ? "translateY(0)" : "translateY(-100%)")} !important;

 */
