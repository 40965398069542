import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {LocomotiveScrollProvider} from "react-locomotive-scroll";
import PageTransition from "../components/PageTransition";
import {graphql, Link, useStaticQuery} from "gatsby";
import SEO from "../components/SEO";
import Text from "../components/MyPortableText";
import Navigation from "../components/Navigation";
import {GatsbyImage} from "gatsby-plugin-image";
import YouTube from "react-youtube";
import MyPortableText from "../components/MyPortableText";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import {localeLink} from "../util";
import CookiePopup from "../components/CookiePopup";
import localize from "../components/localize";


const Layout = ({ children, location }) => {

    const {allSanityStartseite, allSanitySettings, site} = useStaticQuery(graphql`
        query siteSettings {
            allSanityStartseite{
                edges {
                    node {
                        servicesheadline{
                            _type
                            en
                            de
                        }
                        aboutheadline{
                            _type
                            en
                            de
                        }
                        photoheadline{
                            _type
                            en
                            de
                        }
                        videoheadline{
                            _type
                            en
                            de
                        }
                        factsheadline{
                            _type
                            en
                            de
                        }
                    }
                }
            }
            allSanitySettings{
                edges {
                    node {
                        maintenance{
                            maintenancewebsite
                            maintenanceactive
                            maintenance_seo_description{
                                _type
                                en
                                de
                            }
                            maintenanceimage{
                                asset{
                                    gatsbyImageData
                                }
                            }
                            maintenance_seo_title{
                                _type
                                en
                                de
                            }
                            maintenancetitle{
                                _type
                                en
                                de
                            }
                            maintenancebutton {
                                value
                                title
                            }
                            _rawMaintenancetext
                        }
                    }
                }
            }
            site {
                siteMetadata {
                    lang{
                        _type
                        en
                        de
                    }
                    work{
                        _type
                        en
                        de
                    }
                    aboutme{
                        _type
                        en
                        de
                    }
                    contact{
                        _type
                        en
                        de
                    }
                }
            }
        }
    `)

    const [maintenanceMode, setMaintenanceMode] = useState(true);

    useEffect(() => {
        if (location?.hostname !== 'ijob.at') {
            setMaintenanceMode(false);
        }
    }, [location]);

    const staticSettings = allSanitySettings.edges[0].node;
    const staticStartseite = allSanityStartseite.edges[0].node;
    const metadata = site.siteMetadata;

    if (staticSettings?.maintenance?.maintenanceactive && maintenanceMode) {
        console.log('maintenance mode aktiv')
        return (<>
            <SEO title={staticSettings?.maintenance.maintenance_seo_title.de} description={staticSettings?.maintenance.maintenance_seo_description} location={location}></SEO>
            <div className="landing__outer div100">
                <GatsbyImage className={"fullbackgrounddiv"} image={staticSettings.maintenance.maintenanceimage?.asset?.gatsbyImageData} alt={"Work by Ijob Brandstätter"}></GatsbyImage>
                <div className="landing__inner">
                    <div className="animation__outer animation__outer__headline h1-animated">
                        <h1 className="color-light landing__headline" >
                            {staticSettings.maintenance.maintenancetitle.de}
                        </h1>
                    </div>
                    <div className="animation__outer hoverable">
                        <div className={"btn__landing__outer"}>
                            <Link to={localeLink(staticSettings.maintenance.maintenancebutton.value, location)} className="btn__landing btn btn-red hover-moveup">{staticSettings.maintenance.maintenancebutton.title}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    } else {
        console.log('maintenance mode inaktiv')
        return (<>
                <Navigation location={location} lang={metadata.lang} animated={true} metadata={metadata} servicesheadline={staticStartseite.servicesheadline} photoheadline={staticStartseite.photoheadline} aboutheadline={staticStartseite.aboutheadline} videoheadline={staticStartseite.videoheadline}></Navigation>
                    {children}
        </>)
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
